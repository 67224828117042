<template>
  <b-container fluid="xxl">

    <b-row>
      <b-col cols="12"   xl="6" xxl="4">

        <h3>Transcriber</h3>

        <b-button @click="openNewTranscription()" type="button" variant="cta" class="button-cta mt-4">
          <font-awesome-icon icon="fa-solid fa-plus" /> {{ $t('CREATE_NEW_TRANSCRIPT') }}
        </b-button>

      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12"   xl="6" xxl="4">

        <template  v-if="articledata.length == 0">
          <p class="mb-5" v-html="$t('NO_TOOLS_IN_LIST')"></p>
        </template>

      </b-col>
    </b-row>


    <b-row v-if="articledata.length > 0">

      <!-- item -->
      <b-col
          v-for="(article, article_index) in articledata"
          :key="article_index"
          cols="12" xl="6" xxl="4"
          class="mt-4 mb-3"
      >
        <ProductCard
            :no-padding="true"
            :product-id="article.module_media_id  | tostring"
            :product-title="article.title"
            :product-image="article.image.url"
            :product-cta-link="article.link"
            :product-btn-label="$t('LETS_START')"
            :badge-text="$t(article.badge_text)"
            :small-layout="true"
            :product-description="article.short_text_truncated"
            :is-transcriber-content="1"
            :is-transcriber-is-in-progress="article.in_progress"
            :is-transcriber-waiting-for-payment="article.is_waiting_for_payment"
            v-on:submit="handleToolEvent"

        />


      </b-col>

    </b-row>


    <b-modal
        id="new-transcription"
        size="xl"
        title="Neuen Inhalt transkribieren und zusammenfassen">

      <!-- item -->

      <b-row>

        <b-col cols="12">

          <b-form-group
              v-show="show_modal_url"
              label="Medien-URL (mehrere möglich):"
              label-for="media_url"
          >
            <b-input-group>
              <b-form-input
                  name="media_url"
                  id="media_url"
                  type="text"
                  placeholder="URL / Video"
                  :state="validation('media_url')"
                  v-model.trim="formData.media_url"
              ></b-form-input>
              <b-input-group-append>
                <b-button
                    type="button"
                    variant="secondary"
                    @click="sendUrlVideo()"
                ><font-awesome-icon
                    :icon="['fa-solid', 'fa-plus']"
                    class="cursor-pointer"
                /></b-button>
              </b-input-group-append>
            </b-input-group>
            <b-form-invalid-feedback :state="validation('media_url')">
              Die URL muss mit https:// oder http:// beginnen. Außerdem muss es eine Youtube-URL, eine TikTok-URL oder ein Link zu einer MP4-, MP3, M4A oder WAV-Datei sein.
            </b-form-invalid-feedback>
            <b-form-valid-feedback :state="validation('media_url')"></b-form-valid-feedback>
          </b-form-group>

        </b-col>
      </b-row>

      <p>Oder lade direkt Dateien hoch:</p>

      <vue-dropzone
          v-show="show_modal_upload"
          ref="exampleDropzone"
          id="dropzoneItem"
          :options="dropzoneOptions"
          v-on:vdropzone-sending="uploadEvent"
          v-on:vdropzone-success="fileUploaded"
      ></vue-dropzone>

      <div
          v-for="(media, media_index) in new_media_items"
          :key="media_index"
      >

        <b-row
            v-if="media_index == 0"
            class="media-line mt-5">

          <b-col cols="5">

            <h5>Medien</h5>

          </b-col>

          <b-col cols="4">

            <h5>Titel</h5>

          </b-col>

          <b-col cols="3">
            <h5>Medien-Typ</h5>
          </b-col>

        </b-row>

        <b-row class="media-line mt-2">

          <b-col cols="5" class="pt-3 pb-3 text-center">

            <span
                v-if="createTag(media) !== ''"
            >
              <span
                  v-if="media.media_type_description === 'Bild'"
              >
                <span
                    v-html="createTag(media)"
                    @click="downloadMedia(media)"
                ></span>
              </span>
              <span
                  v-else
              >
                <span
                    v-html="createTag(media)"
                ></span>
              </span>
            </span>

            <span
                v-if="media.media_type_description === 'URL'"
            >
              <font-awesome-icon
                  :icon="['fa-solid', 'fa-link']"
                  class="link-primary-modal media-icon"
                  @click="downloadMedia(media)"
              />
            </span>

            <span
                v-if="media.media_type_description === 'Dokument'"
            >
              <font-awesome-icon
                  :icon="['fa-solid', 'fa-file']"
                  class="link-primary-modal media-icon"
                  @click="downloadMedia(media)"
              />
            </span>


          </b-col>

          <b-col cols="4" class="pt-3 pb-3" style="word-wrap: break-word">
            <span
                v-if="media.media_type_description != 'Video mp4' || media.media_type_description != 'Audio mp3' || media.media_type_description != 'Audio m4a' || media.media_type_description != 'Audio wav'"
                class="link-primary-modal cursor-pointer"
                @click="downloadMedia(media)">{{ media.ressource }}</span>

            <span
                v-else>{{ media.ressource }}</span>
          </b-col>

          <b-col cols="3" class="pt-3 pb-3">
            {{ media.media_type_description }}
            <br/>
            <font-awesome-icon
                :icon="['fa-solid', 'fa-file-arrow-down']"
                class="link-primary-modal ml-2"
                @click="downloadMedia(media)"
            />

            <font-awesome-icon
                :icon="['fa-solid', 'fa-trash-can']"
                class="cursor-pointer ml-2 text-danger"
                @click="deleteMedia(media.module_media_id)"
            />
          </b-col>

        </b-row>



      </div>

      <div
        v-if="new_media_items.length > 0"
        class="mt-5"
      >

        <b-row>

          <b-col cols="12">

            <h5>Wähle eine Aufgabe:</h5>
            <small>(Du kannst diese auch später noch erweitern.)</small><br/><br/>

            <p><font-awesome-icon
                :icon="['fa-solid', 'fa-check-circle']"
                class="text-success ml-2 mr-2"
            /> Transkription (Speech-to-Text) und Erstellung von SRT-Untertiteln (Text mit Zeitangaben)</p>

            <p
              @click="summary = !summary"
            ><font-awesome-icon
                :icon="['fa-solid', summary ? 'fa-check-circle' : 'fa-circle']"
                :class="summary ? 'text-success ml-2 mr-2' : 'ml-2 mr-2'"
            /> <span onmouseover="this.style.textDecoration='underline';"
                     onmouseout="this.style.textDecoration='none';">Zusammenfassung</span></p>

            <p
                @click="translation = !translation"
            >
            <font-awesome-icon
                :icon="['fa-solid', translation ? 'fa-check-circle' : 'fa-circle']"
                :class="translation ? 'text-success ml-2 mr-2' : 'ml-2 mr-2'"
            /> <span onmouseover="this.style.textDecoration='underline';"
                     onmouseout="this.style.textDecoration='none';">Übersetzung</span></p>

          </b-col>

        </b-row>

        <b-row
          v-show="translation"
          class="mt-3"
        >

          <b-col cols="12">

            <h5>Wähle die Sprachen aus, in die übersetzt werden soll:</h5>
            <small>(Du kannst die Sprachauswahl auch später noch ändern.)</small><br/><br/>

            <div
                v-for="(language, language_index) in languages"
                :key="language_index"
            >

              <p
                  @click="setLanguage(language.language_id)"
              >
                <font-awesome-icon
                    :icon="['fa-solid', checkLanguage(language.language_id) ? 'fa-check-circle' : 'fa-circle']"
                    :class="checkLanguage(language.language_id) ? 'text-success ml-2 mr-2' : 'ml-2 mr-2'"
                /> <span onmouseover="this.style.textDecoration='underline';"
                         onmouseout="this.style.textDecoration='none';">{{ language.name_de }}</span></p>

            </div>

          </b-col>

        </b-row>

        <b-row
            class="mt-3"
        >

          <b-col cols="12">

            <h5>Wichtige Hinweise:</h5>

            <h6 class="mt-5">Token-Abrechnung</h6>

            <ul>
              <li>Für jeden Vorgang (Transkription, Zusammenfassung, Übersetzung) und für jede gewählte Sprache werden Token abgezogen – pro Medium (z.B. YouTube, Vimeo, TikTok oder Deine Uploads).</li>
              <li>Die Anzahl der benötigten Token richtet sich nach der Länge der Medien und der Anzahl der gesprochenen Worte.</li>
              <li>Nach jedem Vorgang erhältst Du eine detaillierte Auflistung der Kosten.</li>
              <li>Deine Uploads bleiben privat. Öffentlich zugängliche Daten, wie YouTube-Videos, die bereits von unserer Community transkribiert wurden, werden Dir nicht erneut berechnet.</li>
            </ul>

            <h6>Verbotene Inhalte</h6>

            <ul>
              <li>Bitte beachte die geltenden Gesetze. Sittenwidrige oder verbotene Inhalte sind in unserer Community nicht erlaubt und werden gelöscht.</li>
              <li>Bei Verstößen wird Dein Account gelöscht und es erfolgt eine strafrechtliche Verfolgung.</li>
            </ul>

            <h6>Vorgang starten</h6>

            Mit dem Klick auf den Button werden die Vorgänge gestartet, Deine Token reduziert und nach Abschluss erhältst Du eine Benachrichtigung.

            Viel Spaß mit den Inhalten! :-)

          </b-col>

        </b-row>
      </div>


      <template #modal-footer="{ ok, cancel }">
        <b-button
            v-show="new_media_items.length > 0"
            variant="secondary" @click="ok() & startTranscription()">
          Vorgang starten
        </b-button>
        <b-button size="sm" class="btn button-cta btn-cta" @click="cancel()">
          Schließen
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>


<script>
import {mapActions, mapGetters} from "vuex";
import ProductCard from '@/components/ProductCard.vue';
import axios from "axios";
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
  name: "areaTranscriber",
  components: {
    ProductCard,
    vueDropzone: vue2Dropzone,
  },
  data () {
    return {
      articledata: [],
      formData: {
        title: '',
        url: '',
        price: '',
        pseudoprice: '',
        description: '',
        product_type: '',
        media_url: '',
      },
      formDataError: {},


      API_URL_UPLOAD: process.env.VUE_APP_UPLOAD,
      API_URL: process.env.VUE_APP_API,
      max: 10,
      uvdata: '',

      dropzoneOptions: {
        url: process.env.VUE_APP_UPLOAD,
        thumbnailWidth: 150,
        maxFilesize: 8000 * 1024 * 1024,
        parallelUploads: 5,
        chunking: true,
        forceChunking: true,
        chunkSize: 10 * 1024 * 1024,
        parallelChunkUploads: false,
        retryChunks: true,
        retryChunksLimit: 3,
        /*
        init: function() {
          this.on("uploadprogress", function(file, progress) {
            console.log("File progress in %", progress);
          });
        },*/
        timeout: null,
        maxFiles: 1,
        acceptedFiles: ".mp4,.mp3,.m4a,.wav",
        addRemoveLinks: true,
        dictDefaultMessage: "<i class='fa fa-cloud-upload'></i>Lade hier Video-Dateien (nur .mp4), MP3- / M4A-Dateien oder WAV-Dateien hoch.",
        headers: { "members.myshopbooster.de": "TranscriberUpload" },
        chunksUploaded: (file, done) => {
          this.chunkFinish(file).then(done());
        },
      },

      busy: false,
      processing: false,
      counter: 1,
      interval: null,
      delete_item_id: 0,

      delete_chapter_id: 0,
      delete_media_id: 0,

      previewModalTitle: '',
      previewModalContent: '',

      module_category_id: 1,
      module_id: 1,
      show_modal_url: true,
      show_modal_upload: true,

      new_media_items: [],
      languages: [],
      transcription: true,
      summary: false,
      translation: false,
      selected_languages: [],
    }
  },
  mounted() {
    this.loadToolData();
    this.getLanguages();

  },
  computed: {
    ...mapGetters( {
    }),

    getMedia() {
      return this.new_media_items;
    },

    itemTitle: {
      get () {
        //return this.getCourseItem.item_title;
        return 1;
      },
      set (value) {
        //this.$store.dispatch('setCourseItem', {item_title: value});
        console.log(value);
      }
    },

  },
  methods: {
    ...mapActions([
    ]),

    async loadToolData() {
      //const response = await axios.get('/staticMockData/transcriptmate.json');
      //this.articledata = response.data.articles;

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_transcript_user_data',
            cookie: cookie
          }
      );

      console.log(response);

      if (response.data.error) {

        //console.log(response);

      } else {

        this.articledata = response.data;
      }

    },

    async handleToolEvent(data) {
      if(!data || !("type" in data) ) return;
      const url = data.url;
      if (this.$route.path !== url) this.$router.push(url);
    },

    openNewTranscription() {
      this.$bvModal.show('new-transcription');
    },

    toggleModalUpload() {
      if (this.show_modal_upload) {
        this.show_modal_upload = false;
      } else {
        this.show_modal_upload = true;
      }
    },

    toggleModalUrl() {
      if (this.show_modal_url) {
        this.show_modal_url = false;
      } else {
        this.show_modal_url = true;
      }
    },

    validation(type) {

      if (this.formData[type] === undefined) return true;

      var ret = false;
      var data = this.formData[type];

      switch(type) {

        case 'item_title':

          ret = true;
          if (data.length < 2) {
            ret = false;
          }

          break;

        case 'url':

          if (data.startsWith('https://') || data === '') {
            ret = true;
          } else {
            ret = false;
          }

          break;

        case 'media_url':

          console.log(data);
          if (data === '') {
            ret = true;
          } else if (
              data.toLowerCase().startsWith('https://')
              || data.toLowerCase().startsWith('http://')
          ) {

            if (data.toLowerCase().search('youtube') > -1
                || data.toLowerCase().search('youtu.be') > -1
                || data.toLowerCase().search('tiktok') > -1
                || data.toLowerCase().endsWith('.mp4')
                || data.toLowerCase().endsWith('.mp3')
                || data.toLowerCase().endsWith('.m4a')
                || data.toLowerCase().endsWith('.wav')
            ) {
              ret = true;
            } else {
              ret = false;
            }

          } else {
            ret = false;
          }

          break;

        default:
          ret = true;
          break;


      }

      this.formDataError[type] = ret;
      this.isFormError();
      return ret;

    },
    isFormError() {

      var form = this.formDataError;

      //console.log(form);
      if (form.length === 0) return true;

      for (const key in form) {
        //console.log(key);
        if (!form[key]) {
          //console.log(false);
          return false;
        }
      }

      return true;

    },

    async sendUrlVideo() {

      if (this.formData.media_url === '') return;

      //this.toggleModalUpload();

      //todo: url validierung, nur wenn ok
      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'new_transcript_url',
            url: this.formData.media_url,
            cookie: cookie
          }
      );

      //console.log(response);

      if (response.data.error) {

        //console.log(response);

      } else {

        //this.$store.dispatch('xxxxsetCourseItemMedia', {item: response.data});
        //Object.assign(this.new_media_items, response.data );
        this.new_media_items.push(response.data);
      }

      this.formData.media_url = '';

    },

    uploadEvent (file, xhr, formData) {

      console.log(file, xhr, formData);

      formData.append('module_id', this.module_id);

    },

    fileUploaded(file, response) {
      console.log('fileUploaded');
      console.log(file);
      console.log('response');
      console.log(response);

      if (response == '') return;

      let result = JSON.parse(response);

      console.log(result);

      //console.log(result.product_media_id);
      //console.log(result);

      //if (result.product_media_id === undefined) return;

      //this.$store.dispatch('setCourseItemMedia', {item: result});

    },

    createTag(media) {

      if(media.media_type_description === undefined) return '';

      var str = '';

      switch(media.media_type_description) {

        case 'Video mp4':
          str = '<video width="100%" height="100%" controls volume="50" preload="auto"><source src="' + media.url + '" type="video/mp4"/></video>';
          break;

        case 'Audio mp3':
          str = '<audio controls volume="50" preload="auto"><source src="' + media.url + '" type="audio/mp3"/></audio>';
          break;

        case 'Audio m4a':
          str += '';
          break;

        case 'Bild':
          str += '<img src="' + media.url + '" width="100" border="0"/>';
          break;

        case 'Dokument':
          str += '';
          break;

        case 'URL':
          str += "";
          break;

        case 'Vimeo':
          //str = '<iframe src="http://player.vimeo.com/video/' + media.filename_no_extension + '" width="100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>';

          str = '<div style="padding:51.3% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/' + media.filename_no_extension + '?h=1b6c086b95&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Back to Top Link"></iframe></div>';
          break;

        case 'Youtube':
          str = '<iframe width="100%" src="https://www.youtube-nocookie.com/embed/' + media.filename_no_extension + '" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
          break;

      }

      //console.log(str);

      return str;

    },

    async chunkFinish(file) {

      //console.log('chunkfinish');
      //console.log(file);

      // Alle Chunks sind hochgeladen
      let currentFile = file;
      //let item = this.getCourseItem;
      let upload_str = '';

      //console.log('currentfile', currentFile);

      if ("xhr" in currentFile) {

        if ("response" in currentFile.xhr) {

          let file_response = JSON.parse(currentFile.xhr.response);

          upload_str = file_response.upload_str;

        }
      }

      const response = await axios.post(
          this.API_URL_UPLOAD,
          {
            module_id: this.module_id,
            upload_dzuuid: currentFile.upload.uuid,
            upload_dztotalchunkcount: currentFile.upload.totalChunkCount,
            filename: currentFile.name,
            upload_str: upload_str,
          }
      );

      //console.log('responseFinish', response);

      if (response == '') return;

      //let result = JSON.parse(response.data);
      let result = response.data;

      //console.log('result', result);
      if (result.module_media_id === undefined) return;

      //this.$store.dispatch('setCourseItemMedia', {item: result});
      //Object.assign(this.new_media_items, { result });
      this.new_media_items.push(result);
    },

    downloadMedia(ressource) {

      //console.log(ressource);

      if ("url" in ressource) {
        //window.location.href = ressource.url;

        let a= document.createElement('a');
        a.target= '_blank';
        a.href= ressource.url;
        a.click();
      }
    },

    deleteMedia(module_media_id) {

      let tmp = [];

      for (let i = 0; i < this.new_media_items.length; i++) {
        if (this.new_media_items[i].module_media_id !== module_media_id) {
          tmp.push(this.new_media_items[i]);
        }
      }

      this.new_media_items = tmp;
    },

    async getLanguages() {

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_transcript_languages',
            cookie: cookie
          }
      );

      //console.log(response);

      if (response.data.error) {

        //console.log(response);

      } else {

        this.languages = response.data;

      }


    },

    checkLanguage(language_id) {

      for (let i = 0; i < this.selected_languages.length; i++) {
        if (this.selected_languages[i].language_id == language_id) {
          return true;
        }
      }

      return false;
    },

    setLanguage(language_id) {

      //console.log('setLanguage', language_id);
      let tmp = [];
      var isda = false;
      var actual_language = [];

      //console.log('selected_languages', this.selected_languages);

      for (let i = 0; i < this.languages.length; i++) {

        if (this.languages[i].language_id == language_id) {

          actual_language = this.languages[i];

          for (let a = 0; a < this.selected_languages.length; a++) {

            if (this.selected_languages[a].language_id == language_id) {
              isda = true;
            }
          }
        }
      }

      for (let a = 0; a < this.selected_languages.length; a++) {

        if (this.selected_languages[a].language_id !== language_id) {
          tmp.push(this.selected_languages[a]);
        }

      }

      if (!isda) {
        tmp.push(actual_language);
      }

      this.selected_languages = tmp;
    },

    async startTranscription() {

      //console.log('startTranscription');

      let module_media_ids = [];

      for( let i = 0; i < this.new_media_items.length; i++) {
        module_media_ids.push({ module_media_id: this.new_media_items[i].module_media_id });
      }

      let language_ids = [];

      for( let i = 0; i < this.selected_languages.length; i++) {
        language_ids.push({ language_id: this.selected_languages[i].language_id });
      }

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'new_transcript_todo',
            transcription: this.transcription,
            summary: this.summary,
            translation: this.translation,
            selected_languages: language_ids,
            new_media_items: module_media_ids,
            cookie: cookie
          }
      );

      //console.log(response);

      if (response.data.error) {

        //console.log(response);

      } else {

        this.new_media_items = [];
        this.selected_languages = [];
        this.summary = false;
        this.translation = false;

        //this.languages = response.data;

      }

    },







  },






}
</script>

<style scoped>

</style>