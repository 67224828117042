// For more Icons take a lokk at https://fontawesome.com/

const WLCONFG= {
    SHOW_BLOG:                      false,
    SHOW_AI:                        false,
    USE_CONTENT_IMAGE_ERROR_PAGE:   true,
    SHOW_DASHBOARD_SIDEBAR:         false,
    SHOW_BLOG_SIDEBAR:              true,
    SHOW_BLOG_ARTICLE_SIDEBAR:      true,
    SHOW_ACADEMY_SIDEBAR:           false,
    SHOW_ACADEMY_LESSON_SIDEBAR:    true,
    SHOW_PRODUCT_SIDEBAR:           false,
    SHOW_CHECKLIST_SIDEBAR:         false,
    SHOW_STATISTIC_SIDEBAR:         false,
    SHOW_TRANSCRIBER_SIDEBAR:       false,
    SHOW_AITOOLS_SIDEBAR:           false,
	ACCESSLEVEL:                    '-1',
    ICON_NAVIGATION_DASHBOARD:      'gauge-high',
    ICON_NAVIGATION_MY_PRODUCTS:    'cart-arrow-down',
    ICON_NAVIGATION_STATISTICS:     'chart-pie',
    ICON_NAVIGATION_ACADEMY:        'graduation-cap',
    ICON_NAVIGATION_BLOG:           'book',
    ICON_NAVIGATION_AI:             'robot',
    ICON_NEWS:                      'bullhorn',
    ICON_CHARTS:                    'chart-area',
    ICON_OFFER:                     'basket-shopping',
    LOGO_SIGNET:                    'logo_signet.svg',
    LOGO:                           'logo_variant_2.svg',
    ERROR_LOGO:                     'tu_error_logo.png',
	DASHBORAD_VIDEO:                'Marketingvideo_v1.mp4',
	DASHBORAD_VIDEO_POSTER:         'poster_default-thomas.png',
    APP_META_TITLE:                 'Thomas Urban',
    FAVICON_FOLDER:                 'tu',
    META_SAFE_EQUIV:                'member.thomas-urban.consulting',
    COMPANY_NAME:                   'Urban Beteiligungs GmbH',
    COMPANY_SHORT_NAME:             'Urban Beteiligungs GmbH',
    COMPANY_ADDRESS:                'Marlene-Dietrich-Allee 14, 14482 Potsdam, Deutschland',
    COMPANY_EMAIL:                  'info@thomas-urban.consulting',
    COMPANY_PHONE:                  '030 - 609 83 09 60'
}

export  {
    WLCONFG
}
